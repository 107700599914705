import "./index.css";
import { React } from "react";
import ReactDOM from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { SnackbarProvider } from "notistack";
import App from "./App";

const customClasses = {
  root: 'snackbar-default',
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY} useRecaptchaNet="false" useEnterprise="true">
    <SnackbarProvider maxSnack={1} classes={customClasses}>
      <App></App>
    </SnackbarProvider>
  </GoogleReCaptchaProvider>
);
