import { React, Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loading from "./components/Common/Loading";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { useSnackbar, closeSnackbar } from "notistack";
const FileNotFound = lazy(() => import("./components/Common/FileNotFound"));
const MainPage = lazy(() => import("./MainPage"));
const EventsPage = lazy(() => import("./components/Pages/Events/EventsPage"));
const TermsAndConditions = lazy(() => import("./components/Pages/TermsAndPrivacy/TermsConditions"));
const PrivacyPolicy = lazy(() => import("./components/Pages/TermsAndPrivacy/PrivacyPolicy"));
const ReturnPolicy = lazy(() => import("./components/Pages/TermsAndPrivacy/ReturnPolicy"));
const CookiePolicy = lazy(() => import("./components/Pages/TermsAndPrivacy/CookiesPolicy"));
const ClearCache = lazy(() => import("./components/debug/ClearCache"));
const ShopHome = lazy(() => import("./components/Pages/Shop/ShopHome"));



function App() {

  //Setup periodic check for updates
  function checkForUpdates() {
    console.log("Checking for Update.")
    navigator.serviceWorker.controller.postMessage({ type: "CHECK_FOR_UPDATES" });
  }

  useEffect(() => {
    // Set up the updateCheckInterval when the component mounts
    const intervalId = setInterval(() => {
      checkForUpdates()
    }, 43200000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  //Snackbar
  const { enqueueSnackbar } = useSnackbar();
  let key = null;

  function displayNewVersionPrompt(func) {
    if (!key) {
      key = enqueueSnackbar("A new version was released", {
        preventDuplicate: true,
        persist: true,
        variant: "info",
        action: refreshAction(func),
      });
    }
  }

  function refreshAction(func) {
    return (
      <button className="snackbar-button" size="small" onClick={() => func()}>
        Reload
      </button>
    );
  }

  //Service Worker
  //Service Worker Config
  const configuration = {
    onUpdate: (registration) => {
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: "CHECK_FOR_UPDATES" });
      }
    },
  };

  //Update Service Worker

  //listener for update required from service worker
  navigator.serviceWorker.addEventListener("message", (event) => {
    if (event.data && event.data.type === "UPDATE_REQUIRED") {
      displayNewVersionPrompt(() => refreshCacheAndReload());
    }
  });

  function refreshCacheAndReload() {
    if (caches) {
      // Service worker cache should be cleared
      caches.keys().then((cacheNames) => {
        return Promise.all(
          cacheNames.map((cacheName) => {
            return caches.delete(cacheName);
          })
        );
      });
    }

    //Close Snackbar
    closeSnackbar(key);
    key = null;

    //unregister service worker
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
      //Reload Window
      window.location.reload(true);
    });
  }

  //Register Service Worker
  serviceWorkerRegistration.register(configuration);

  return (
    <>
      <Suspense fallback={<Loading></Loading>}>
        <Router>
          <Routes>
            <Route path="/" element={<MainPage />} errorElement={<FileNotFound />} />
            <Route path="/Events-Page" element={<EventsPage />} errorElement={<FileNotFound />} />
            <Route path="/Terms-and-Conditions" element={<TermsAndConditions />} errorElement={<FileNotFound />} />
            <Route path="/Privacy-Policy" element={<PrivacyPolicy />} errorElement={<FileNotFound />} />
            <Route path="/Return-Policy" element={<ReturnPolicy />} errorElement={<FileNotFound />} />
            <Route path="/Cookies-Policy" element={<CookiePolicy />} errorElement={<FileNotFound />} />
            <Route path="/Shop" element={<ShopHome />} errorElement={<FileNotFound />} />
            <Route path="/_ClearAndRefresh" element={<ClearCache refresh={() => {displayNewVersionPrompt(() => refreshCacheAndReload())}} checkUpdate={() => {checkForUpdates()}}/>} errorElement={<FileNotFound />} />
            <Route path="*" exact={true} element={<FileNotFound />} />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
}

export default App;
